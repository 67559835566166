import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

import { AppConfig } from '@config';
import { SessionService } from '@services/session';
import { QueryStringService } from '@services/query-string';

interface IRequestOptions {
  headers: HttpHeaders;
  params: HttpParams;
  body: HttpParams;
  responseType: 'json'; // 'json' | 'blob' | 'text' and so on
}

export interface AuthResp {
  accessToken: string;
  refreshToken: string;
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private requests: any[] = [];
  private isRefreshInProcess = false;

  constructor(
    private http: HttpClient,
    private sessionSrv: SessionService,
    private querySrv: QueryStringService,
  ) {}

  protected getHeaders(headers: { [key: string]: string } = {}): HttpHeaders {
    // const headers = {'X-API-Version': AppConfig.apiVersion};
    if (this.sessionSrv.getToken()) {
      headers['Authorization'] = `Bearer ${this.sessionSrv.getToken()}`;
    }

    return new HttpHeaders(headers);
  }

  protected prepareOptions(options): IRequestOptions {
    return {
      headers: this.getHeaders(options?.headers),
      params: this.querySrv.buildParams(options?.params),
      body: (options && options.body) || {},
      responseType: (options && options.responseType) || 'json',
    };
  }

  get(path, options?): Observable<any> {
    return this.handleExpiredToken(options, (opts) =>
      this.http.get(`${AppConfig.apiUrl}${path}`, opts),
    );
  }

  post(path, body, options?): Observable<any> {
    return this.handleExpiredToken(options, (opts) =>
      this.http.post(`${AppConfig.apiUrl}${path}`, body, opts),
    );
  }

  put(path, body, options?): Observable<any> {
    return this.handleExpiredToken(options, (opts) =>
      this.http.put(`${AppConfig.apiUrl}${path}`, body, opts),
    );
  }

  patch(path, body = {}, options?): Observable<any> {
    return this.handleExpiredToken(options, (opts) =>
      this.http.patch(`${AppConfig.apiUrl}${path}`, body, opts),
    );
  }

  delete(path, options?): Observable<any> {
    return this.handleExpiredToken(options, (opts) =>
      this.http.delete(`${AppConfig.apiUrl}${path}`, opts),
    );
  }

  handleExpiredToken(options, callToApi: any) {
    // Collect all requests if jwt expired and return custom observer
    if (this.sessionSrv.isRegistered() && this.sessionSrv.isExpired()) {
      const emitter = new Subject();
      const request$ = new Observable<any>((subscriber) => {
        emitter.subscribe((data) => {
          subscriber.next(data);
          subscriber.complete();
        });
      });
      this.requests.push({
        request$,
        emitter,
        callToApi,
      });

      if (!this.isRefreshInProcess) {
        this.isRefreshInProcess = true;
        this.refreshToken()
          .pipe(
            finalize(() => {
              // Resend requests after token refreshed
              this.requests.forEach((req) => {
                req
                  .callToApi(this.prepareOptions(options))
                  .subscribe((data) => {
                    req.emitter.next(data);
                    req.emitter.complete();
                  });
              });
              this.requests.length = 0;
              this.isRefreshInProcess = false;
            }),
          )
          .subscribe();
      }
      return request$;
    }

    return callToApi(this.prepareOptions(options));
  }

  refreshToken(): Observable<any> {
    const body = { refresh_token: this.sessionSrv.getRefreshToken() };
    // const headers = new HttpHeaders({'X-API-Version': AppConfig.apiVersion});
    const headers = new HttpHeaders({});
    return this.http
      .post(`${AppConfig.apiUrl}/refresh_token`, body, { headers })
      .pipe(tap((data: AuthResp) => this.sessionSrv.saveData(data)));
  }
}
