<div class="input-container input-type-toggle {{ wrapperCssClass }}">
  <mat-label></mat-label>
  <mat-slide-toggle
      color="primary"
      [formControl]="fControl"
      (change)="onChange($event)"
  >
    {{ label }}
  </mat-slide-toggle>
</div>
