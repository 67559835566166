<app-filters (emitRequest)="filterOnRequest($event)" [searchKeys]="filterSearchKeys" [filters]="filters"></app-filters>

<div class="card">
  <div class="table-wrapper">
    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="text">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>text</th>
        <td mat-cell *matCellDef="let element">{{ element.text }}</td>
      </ng-container>

      <ng-container matColumnDef="user">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>author</th>
        <td mat-cell *matCellDef="let element" class="link" [routerLink]="UsersRoute.getDetailsPath(element.user?._id)">
          {{ element.user?.name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="subject">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>subject</th>
        <td mat-cell *matCellDef="let element" class="link" [routerLink]="PostsRoute.getDetailsPath(element.subject)">
          post
        </td>
      </ng-container>

      <ng-container matColumnDef="isPublic">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>public</th>
        <td mat-cell *matCellDef="let element"><app-boolean [isTrue]="element.isPublic"></app-boolean></td>
      </ng-container>

      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>created</th>
        <td mat-cell *matCellDef="let element">{{ element.createdAt | moment: 'll' }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; let element; columns: displayedColumns"
        [routerLink]="Route.getDetailsPath(element._id)"
      ></tr>
    </table>

    <app-waiting *ngIf="acts.isLoading"></app-waiting>
  </div>

  <app-no-data *ngIf="!dataSource.data.length"></app-no-data>
  <div class="table-paginator-wrapper">
    <app-universal-button 
      btnTitle="add new" 
      icon="add" 
      [routerLink]="Route.ADD_PATH"
      [queryParams]="{type: 'Post', subject: postId}"
    ></app-universal-button>
    <mat-paginator [pageSizeOptions]="Paginator.PageSizeOpts" showFirstLastButtons></mat-paginator>
  </div>
</div>
