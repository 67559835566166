import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { StorageModule } from './store.module';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorInterceptor } from '../interceptors/error-interceptor/error.interceptor';

import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { JwtModule } from '@auth0/angular-jwt';
import { AppConfig } from '@config';
import { QuillModule } from 'ngx-quill';

export function jwtTokenGetter() {
  return null;
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StorageModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    RouterModule,
    NgProgressModule.withConfig({
      spinnerPosition: 'left',
      color: '#25f800',
    }),
    NgProgressHttpModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: jwtTokenGetter,
        skipWhenExpired: true,
        authScheme: '',
        whitelistedDomains: AppConfig.whitelistedDomains,
      },
    }),
    QuillModule.forRoot(),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: ErrorInterceptor,
    },
  ],
  exports: [NgProgressModule],
})
export class CoreModule {}
