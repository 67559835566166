import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { ApiService } from '@services/api';
import { PaginatedData, QueryParams, UploadFileResp } from '@interfaces';
import {
  User,
  UserCreateReq,
  UserUpdateReq,
} from '../interfaces/user.interface';
import { IFileData } from '@services/file-transfer';
import { FormsService } from '@services/forms';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private apiSrv: ApiService, private formsSrv: FormsService) {}

  getAll(queryParams: QueryParams): Observable<PaginatedData<User>> {
    return this.apiSrv.get('/user/paginated', { params: queryParams });
  }

  getOne(id: string): Observable<User> {
    return this.apiSrv.get(`/user/${id}`);
  }

  getUserByEmail(email: string): Observable<User> {
    return this.apiSrv.get(`/user/find-user-by-email/${email}`);
  }

  reSendEmailVerification(body: { userId: string }): Observable<{}> {
    return this.apiSrv.post(`/user/resend-email-verification`,  body);
  }

  accountStatusUpdate(body: { id: string }): Observable<User> {
    return this.apiSrv.patch(`/user/update-account-status`, body);
  }

  delete(body: { id: string }): Observable<User> {
    return this.apiSrv.delete(`/user`, { body });
  }

  create(tag: UserCreateReq): Observable<User> {
    return this.apiSrv.post(`/user`, tag);
  }

  update(tag: UserUpdateReq): Observable<User> {
    return this.apiSrv.patch(`/user`, tag);
  }

  upload(data: IFileData): Observable<UploadFileResp> {
    const body = this.formsSrv.createFormData({ image: data.file });
    return this.apiSrv.post(`/user/upload-avatar`, body);
  }

  getAdmin()   {
    return this.apiSrv.get(`/admin/current-admin`);
  }

  getRedeem(userId: string|null) {
    return this.apiSrv.get(`/entry-code/user/${userId}`);
  }

  getCSV(params: string) {
    return this.apiSrv.get(`/user/export?fields=${params}`, {responseType: 'blob'});
  }

  applyNewEntryCode(code: string, user: string) {
    return this.apiSrv.put(`/entry-code/user/update-user-entry-code`, {code, user});
  }
}
