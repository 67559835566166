import {
  AfterContentInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';

const currYear = new Date().getFullYear();
const MIN_DATE = new Date(currYear - 10 + '');
const MAX_DATE = new Date(currYear + 10 + '');

export const MY_FORMATS = {
  parse: {
    dateInput: 'll',
  },
  display: {
    dateInput: 'll',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'll',
    monthYearA11yLabel: 'MMM YYYY',
  },
};

@Component({
  selector: 'app-universal-datepicker',
  templateUrl: './universal-datepicker.component.html',
  styleUrls: ['./universal-datepicker.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class UniversalDatepickerComponent implements AfterContentInit {
  @ViewChild('picker', { static: false }) picker: ElementRef;

  @Input() fControl: FormControl | AbstractControl;

  @Input() label = '';
  @Input() placeholder = '';
  @Input() clearBtn = false;
  @Input() required = false;
  @Input() cssClass = '';
  @Input() class = ''; // will give effect like <app-universal-input class="input-container short">

  @Input() minDate = MIN_DATE;
  @Input() maxDate = MAX_DATE;

  @Output() emitRequest = new EventEmitter();

  ngAfterContentInit() {}

  initValidate() {
    // Dirty hack to init validating
    // don't use  [min]="minDate" [max]="maxDate" because validation isn't async!
    this.fControl.updateValueAndValidity();
    if (this.fControl.valid) this.emitRequest.emit();
  }

  openPicker() {
    // this.picker.nativeElement.click();
  }
}
