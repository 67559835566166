import { Injectable } from '@angular/core';
import { ApiService } from '@services/api';
import { PaginatedData, QueryParams, UploadFileResp } from '@interfaces';
import { Observable } from 'rxjs';
import { CreatePost, UpdatePost, Post } from '../interfaces/post.interface';
import { IFileData } from '@services/file-transfer';
import { FormsService } from '@services/forms';

@Injectable({
  providedIn: 'root',
})
export class PostsService {
  constructor(private apiSrv: ApiService, private formsSrv: FormsService) {}

  getAll(queryParams: QueryParams): Observable<PaginatedData<Post>> {
    return this.apiSrv.get('/post/paginated', { params: queryParams });
  }

  getOne(id: string): Observable<Post> {
    return this.apiSrv.get(`/post/${id}`);
  }

  create(post: CreatePost): Observable<Post> {
    return this.apiSrv.post(`/post/create-admin`, post);
  }

  update(post: UpdatePost): Observable<Post> {
    return this.apiSrv.patch(`/post`, post);
  }

  updateExpertCenter(post: UpdatePost): Observable<Post> {
    return this.apiSrv.patch(`/post/expert-center`, post);
  }

  delete(body: { id: string }): Observable<Post> {
    return this.apiSrv.delete(`/post`, { body });
  }

  upload(data: IFileData): Observable<UploadFileResp> {
    const body = this.formsSrv.createFormData({ image: data.file });
    return this.apiSrv.post(`/post/upload-image`, body);
  }
}
