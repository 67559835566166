import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AUTH_ROOT, AUTHORIZED_ROOT } from '@const';

const routes: Routes = [
  {
    path: AUTH_ROOT,
    loadChildren: () =>
      import('./_pages_/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: AUTHORIZED_ROOT,
    loadChildren: () =>
      import('./_pages_/content-wrapper/content-wrapper.module').then(
        (m) => m.ContentWrapperModule,
      ),
  },
  {
    path: '**',
    redirectTo: AUTHORIZED_ROOT,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
