import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

import { Local } from '@const';
import { StorageService } from '@services/storage';
import { AuthResp } from '@services/api';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  constructor(
    private storageSrv: StorageService,
    private jwtSrv: JwtHelperService,
  ) {}

  isRegistered() {
    return !!this.getToken();
  }

  saveData({ accessToken, refreshToken }: AuthResp, rememberMe = true): void {
    const isStayLogged =
      rememberMe || this.storageSrv.getItem(Local.TOKEN, localStorage);
    this.clearData();

    const storage = isStayLogged ? localStorage : sessionStorage;
    if (accessToken) this.storageSrv.setItem(Local.TOKEN, accessToken, storage);
    if (refreshToken) {
      this.storageSrv.setItem(Local.REFRESH_TOKEN, refreshToken, storage);
    }
  }

  isExpired(): boolean {
    return this.jwtSrv.isTokenExpired(this.getToken() || '');
  }

  getToken(): string | null {
    return this.storageSrv.getItem(Local.TOKEN);
  }

  getRefreshToken(): string | null {
    return this.storageSrv.getItem(Local.REFRESH_TOKEN);
  }

  getOwnID(): string {
    return this.storageSrv.getItem(Local.USER) || '';
  }

  clearData() {
    [Local.TOKEN, Local.REFRESH_TOKEN, Local.USER].forEach((src) => {
      this.storageSrv.removeItem(src);
      this.storageSrv.removeItem(src, sessionStorage);
    });
  }
}
