import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PromptParams } from '@services/modal';

@Component({
  selector: 'app-universal-prompt',
  templateUrl: './universal-prompt.component.html',
  styleUrls: ['./universal-prompt.component.scss'],
})
export class UniversalPromptComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PromptParams,
    private dialogRef: MatDialogRef<UniversalPromptComponent>,
  ) {}

  ngOnInit() {}

  submit() {
    // emit "isAgree"
    this.dialogRef.close(true);
  }
}
