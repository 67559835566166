export const StubMessage = {
  NOT_FOR_MVP: `This feature isn't available in MVP version.`,
  NOT_IMPLEMENTED: `Implementation in process. (postponed)`,
  NO_DATA: 'No records found',
};

export const SnackbarMessage = {
  ERROR_DEFAULT: 'Something went wrong.',
  FORM_WARNING: 'Please, fill all required fields.',
  SESSION_EXPIRED: 'Session is over. Please, re-login.',
  NO_NETWORK: `Can't fetch data. Please, check your internet connection.`,
  NEEDS_RELOAD: 'Please, reload page.',
  NOT_FOR_MVP: StubMessage.NOT_FOR_MVP,
};
