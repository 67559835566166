export const AUTH_ROOT = 'auth';
export const AUTHORIZED_ROOT = '';

// SIGN IN
const signIn = 'sign-in';
export const SignInRoute = {
  ROOT: signIn,
  ROOT_PATH: `/${AUTH_ROOT}/${signIn}`,
};

// SIGN UP
const signUp = 'sign-up';
export const SignUpRoute = {
  ROOT: signUp,
  ROOT_PATH: `/${AUTH_ROOT}/${signUp}`,
};

const makeRootRoute = (feature: string) => ({
  ROOT: feature,
  ROOT_PATH: `/${feature}`,
});

// DASHBOARD
export const DashboardRoute = makeRootRoute('dashboard');

// PROFILE
export const ProfileRoute = makeRootRoute('');

const makeComplexRoute = (feature: string) => ({
  ROOT: feature,
  ROOT_PATH: `/${feature}`,
  DETAILS: `:id`,
  getDetailsPath: (id) => (id ? `/${feature}/${id}` : null),
  ADD: `add`,
  ADD_PATH: `/${feature}/add`,
  EDIT: `:id/edit`,
  getEditPath: (id) => (id ? `/${feature}/${id}/edit` : null),
});

// USERS
export const UsersRoute = makeComplexRoute('users');

// TAGS
export const TagsRoute = makeComplexRoute('tags');

// SPOTS
export const SpotsRoute = makeComplexRoute('spots');
export const SpotReviewsRoute = makeComplexRoute('spot-reviews');

// POSTS
export const PostsRoute = makeComplexRoute('posts');

// LOVE NOTES
export const LoveNotesRoute = makeComplexRoute('love-notes');

// REPLIES
export const RepliesRoute = makeComplexRoute('replies');

// CORPORATIONS
export const CorporationsRoute = makeComplexRoute('corporations');
export const CorporateSpotsRoute = makeComplexRoute('corporate-spots');
// ARTICLES
export const ArticlesRoute = makeComplexRoute('articles');

// ENTRY CODES
export const EntryCodesRoute = makeComplexRoute('entry-codes');
export const EntryCodeRedeemsRoute = makeComplexRoute('entry-code-redeems');

// SUBSCRIPTIONS
export const SubscriptionsRoute = makeComplexRoute('subscriptions');

// POLLS
export const PollsRoute = makeComplexRoute('polls');
export const PollAnswersRoute = makeComplexRoute('poll-answers');

// CONSULTANT
export const ConsultantRoute = makeComplexRoute('consultant');

// HelpGuide
export const HelpGuideRoute = makeComplexRoute('help-guide');
