<div class="card f-s-sb">
  <form class="filters-block f-s-s f-wrap" [formGroup]="form" (ngSubmit)="submit()">

    <!--  SEARCH QUERY FILTER  -->
    <div class="filter-item filter-search f-c-c " *ngIf = "!searchHidden">
      <app-universal-input
          [fControl]="form.get('searchKey')"
          [optionsArr]="searchKeysOpts"
          label="search in"
          inputType="select"
          class="search-keys"
      ></app-universal-input>
      <app-universal-input
          [fControl]="form.get('query')"
          (emitRequest)="submit()"
          inputType="search"
          clearBtn="true"
          class="search-input"
      ></app-universal-input>
    </div>

    <!-- filters generator -->
    <ng-container  *ngFor="let filter of selectedFilters; let i=index">
      <ng-container [ngSwitch]="filter.type">
        <div class="filter-item filter-{{ filter.type }}">

          <!--  DATE FILTER  -->
          <div class="f-c-c" *ngSwitchCase="'date'">
            <app-universal-datepicker
                [fControl]="filtersForm.get(filter.value).get('from')"
                [label]="filter.viewValue + ' from'"
                (emitRequest)="submit()"
            ></app-universal-datepicker>
            <app-universal-datepicker
                [fControl]="filtersForm.get(filter.value).get('to')"
                [label]="filter.viewValue + ' to'"
                (emitRequest)="submit()"
            ></app-universal-datepicker>
          </div>

          <!--  SELECT FILTER  -->
          <app-universal-input
              *ngSwitchCase="'select'"
              [fControl]="filtersForm.get(filter.value).get('equal')"
              [label]="filter.viewValue"
              inputType="select"
              [optionsArr]="filter.optionsArr"
              (emitSelectChanged)="submit()"
          ></app-universal-input>

          <!--  TEXT FILTER  -->
          <app-universal-input
              *ngSwitchCase="'string'"
              [fControl]="filtersForm.get(filter.value).get('equal')"
              [label]="filter.viewValue"
              useDelay="true"
              (emitRequest)="submit()"
          ></app-universal-input>

          <!-- REMOVE filter button -->
          <app-universal-button
              size="small"
              icon="close"
              class="close-btn"
              tooltip="remove filter"
              (click)="removeFilter(filter, i)"
          ></app-universal-button>
        </div>

      </ng-container>
    </ng-container>

    <!-- dirty workaround to ability submit on 'Enter' key -->
    <button type="submit" [style.display]="'none'"></button>
  </form>


  <!-- ADD FILTER MENU -->
  <div class="f-column">
    <app-universal-button
        btnTitle="add filter"
        icon="filter_list"
        (emitClick)="menuTrigger.openMenu()"
        [disabled]="!availableFilters.length"
    ></app-universal-button>
    <!--  Workaround to prevent toggle on disable  -->
    <button #menuTrigger="matMenuTrigger"  [matMenuTriggerFor]="menu"></button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item *ngFor="let filter of availableFilters" (click)="addFilter(filter)">
        {{ filter.viewValue }}
      </button>
    </mat-menu>
  </div>
</div>
