import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

// MODULES
import { MaterialModule } from './material.module';
import { PipesModule } from '../pipes/pipes.module';
import { RouterModule } from '@angular/router';

// COMPONENTS
import { WaitingComponent } from '@components/waiting/waiting.component';
import { UniversalInputComponent } from '@components/universal-input/universal-input.component';
import { UniversalButtonComponent } from '@components/universal-button/universal-button.component';
import { SnackBarQueueComponent } from '@components/snack-bar-queue/snack-bar-queue.component';
import { SnackBarComponent } from '@components/snack-bar/snack-bar.component';
import { FiltersComponent } from '@components/filters/filters.component';
import { BreadcrumbsComponent } from '@components/breadcrumbs/breadcrumbs.component';
import { UniversalDatepickerComponent } from '@components/universal-datepicker/universal-datepicker.component';
import { NoDataComponent } from '@components/no-data/no-data.component';
import { StubTableComponent } from '@components/stub-table/stub-table.component';
import { TagsChipsComponent } from '../_pages_/tags/components/tags-chips/tags-chips.component';
import { PostsListComponent } from '../_pages_/posts/pages/posts-list/posts-list.component';
import { UniversalToggleComponent } from '@components/universal-toggle/universal-toggle.component';
import { BooleanComponent } from '@components/boolean/boolean/boolean.component';
import { UniversalPromptComponent } from '@components/universal-prompt/universal-prompt.component';
import { RepliesListComponent } from '../_pages_/replies/pages/replies-list/replies-list.component';
import { UsersComponent } from '../_pages_/users/pages/users-list/users.component';
import { UniversalChipInputComponent } from '@components/universal-chip-input/universal-chip-input.component';
import { UniversalChipsComponent } from '@components/universal-chips/universal-chips.component';
import { UploadImageComponent } from '@components/upload-image/upload-image.component';
import { UniversalAutocompleteComponent } from '@components/universal-autocomplete/universal-autocomplete.component';
import { MatAutocompleteOptionsScrollDirective } from '@components/universal-autocomplete/mat-autocomplete-options-scroll/mat-autocomplete-options-scroll.directive';
import { ImagesBlockComponent } from '@components/images-block/images-block.component';
import { UniversalTimepickerComponent } from '@components/universal-timepicker/universal-timepicker.component';
import { MatTimepickerModule } from 'mat-timepicker';

@NgModule({
  declarations: [
    WaitingComponent,
    UniversalInputComponent,
    UniversalButtonComponent,
    UniversalDatepickerComponent,
    UniversalTimepickerComponent,
    UniversalToggleComponent,
    UniversalPromptComponent,
    UniversalChipInputComponent,
    MatAutocompleteOptionsScrollDirective,
    UniversalAutocompleteComponent,
    UniversalChipsComponent,
    UploadImageComponent,
    SnackBarQueueComponent,
    SnackBarComponent,
    FiltersComponent,
    BreadcrumbsComponent,
    NoDataComponent,
    StubTableComponent,
    ImagesBlockComponent,
    TagsChipsComponent,
    BooleanComponent,
    UsersComponent,
    PostsListComponent,
    RepliesListComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    MatTimepickerModule,
    PipesModule,
    ReactiveFormsModule,
  ],
  exports: [
    MaterialModule,
    ReactiveFormsModule,
    WaitingComponent,
    UniversalInputComponent,
    UniversalButtonComponent,
    UniversalDatepickerComponent,
    UniversalTimepickerComponent,
    UniversalToggleComponent,
    UniversalPromptComponent,
    MatAutocompleteOptionsScrollDirective,
    UniversalAutocompleteComponent,
    UniversalChipInputComponent,
    UniversalChipsComponent,
    UploadImageComponent,
    SnackBarQueueComponent,
    SnackBarComponent,
    FiltersComponent,
    BreadcrumbsComponent,
    NoDataComponent,
    StubTableComponent,
    ImagesBlockComponent,
    TagsChipsComponent,
    BooleanComponent,
    PipesModule,
    UsersComponent,
    PostsListComponent,
    RepliesListComponent,
  ],
})
export class SharedModule {}
