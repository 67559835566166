import { Injectable } from '@angular/core';
import { ApiService } from '@services/api';
import { IFileData } from '@services/file-transfer';
import { FormsService } from '@services/forms';
import { Observable } from 'rxjs';

import { PaginatedData, QueryParams, UploadFileResp } from '@interfaces';
import {
  Corporation,
  CorporationCreateReq,
  CorporationUpdateReq,
  NotificationData,
} from '../interfaces/corporation.interface';

@Injectable({
  providedIn: 'root',
})
export class CorporationsService {
  constructor(private apiSrv: ApiService, private formsSrv: FormsService) {}

  getAll(queryParams: QueryParams): Observable<PaginatedData<Corporation>> {
    return this.apiSrv.get('/corporation/paginated', { params: queryParams });
  }

  getOne(id: string): Observable<Corporation> {
    return this.apiSrv.get(`/corporation/${id}`);
  }

  getCorporationByName(name: string): Observable<Corporation> {
    return this.apiSrv.get(`/corporation/find-by-name/${name}`);
  }

  create(corporation: CorporationCreateReq): Observable<Corporation> {
    return this.apiSrv.post(`/corporation`, corporation);
  }

  update(corporation: CorporationUpdateReq): Observable<Corporation> {
    return this.apiSrv.patch(`/corporation`, corporation);
  }

  delete(body: { id: string }): Observable<Corporation> {
    return this.apiSrv.delete(`/corporation`, { body });
  }

  upload(data: IFileData): Observable<UploadFileResp> {
    const body = this.formsSrv.createFormData({ image: data.file });
    return this.apiSrv.post(`/corporation/upload-image`, body);
  }

  sendNotification(data: NotificationData) {
    return this.apiSrv.post(`/corporation/send-notification`, data);
  }
}
