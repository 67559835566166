import { Injectable } from '@angular/core';
import { ApiService } from '@services/api';
import { Observable } from 'rxjs';

import { Role } from '../interfaces/role.interface';
import { PaginatedData, QueryParams } from '@interfaces';

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  constructor(private apiSrv: ApiService) {}

  getAll(queryParams: QueryParams): Observable<PaginatedData<Role>> {
    return this.apiSrv.get('/role/paginated', { params: queryParams });
  }

  list(): Observable<Role[]> {
    return this.apiSrv.get('/role/');
  }

  getOne(id: string): Observable<Role> {
    return this.apiSrv.get(`/role/${id}`);
  }
}
