<div class="input-container {{ cssClass }}">
  <mat-form-field>
    <mat-label>{{ label }}</mat-label>

    <!-- INPUT  // init validation manually because it isn't async! -->
    <input
        matTimepicker
        #picker="matTimepicker"
        #input
        [min]="minDate"
        [max]="maxDate"
        [formControl]="fControl"
        [required]="required"
        [placeholder]="placeholder"
        [mode]="mode"
        (focusin)="input.blur()"
    >

    <!-- CLEAR BUTTON -->
    <button
        type="button" aria-label="Clear"
        mat-button matSuffix mat-icon-button
        (click)="fControl.setValue('')"
        *ngIf="clearBtn && fControl.value">
      <mat-icon>close</mat-icon>
    </button>

    <!-- An icon for the timepicker input that will open the timepicker dialog when clicked -->
    <mat-icon matSuffix (click)="picker.showDialog()">access_time</mat-icon>

    <!-- ERROR MESSAGE // don't use *ngIf because of ExpressionChangedAfterItHasBeenCheckedError-->
    <mat-error [style.display]="fControl.invalid ? 'inline-block' : 'none'">
      {{ fControl.errors | getErrorMessage | stringNormalizer }}
    </mat-error>
  </mat-form-field>
</div>
