import { Injectable } from '@angular/core';
import { ApiService } from '@services/api';
import { Observable, of } from 'rxjs';

import { PaginatedData, QueryParams } from '@interfaces';
import { CreateReply, Reply, UpdateReply } from '../interfaces/reply.interface';
import { FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class RepliesService {
  constructor(private apiSrv: ApiService) { }

  getAll(queryParams: QueryParams): Observable<PaginatedData<Reply>> {
    return this.apiSrv.get('/reply/paginated', { params: queryParams });
  }

  getOne(id: string): Observable<Reply> {
    return this.apiSrv.get(`/reply/${id}`);
  }

  create(reply: CreateReply): Observable<Reply> {
    return this.apiSrv.post(`/reply/create-admin`, reply);
  }

  update(reply: UpdateReply): Observable<Reply> {
    return this.apiSrv.patch(`/reply`, reply);
  }

  delete(body: { id: string }): Observable<Reply> {
    return this.apiSrv.delete(`/reply`, { body });
  }

  isObjectIdValid = (control: FormControl) => {
    if (`${control.value}`.match(/^[a-f\d]{24}$/i)) {
      const post = this.apiSrv.get(`/post/${control.value}`);
      const spot = this.apiSrv.get(`/spot/${control.value}`);

      return new Promise(resolve => {
        post.subscribe(p => {
          resolve(p._id ? null : { err: 1 });
        }, () => {
          spot.subscribe(s => {
            resolve(s._id ? null : { err: 1 });
          }, () => {
            resolve({ err: 1 });
          });
        });
      });
    } else {
      return of({ err: 1 });
    }
  }
}
