import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarRef,
} from '@angular/material/snack-bar';

import { SnackbarMessage } from '@const';
import { SnackBarComponent } from '@components/snack-bar/snack-bar.component';
import { SnackBarQueueComponent } from '@components/snack-bar-queue/snack-bar-queue.component';

export interface Snackbar {
  type: 'error' | 'success' | 'info' | 'warning';
  message: string;
  title?: string;
  _snackRef?: MatSnackBarRef<any>;
}

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(private snackBar: MatSnackBar) {}

  open(data?: Snackbar, duration?: number) {
    data = data || this.resetData('info');

    const config = new MatSnackBarConfig();
    config.panelClass = ['i-snackbar', 'i-snackbar-' + data.type];
    config.duration = duration || 10000;
    config.data = data;
    // Note: Position loses on chunk error (Fixed via CSS)
    config.verticalPosition = 'bottom';
    config.horizontalPosition = 'right';

    config.data._snackRef = this.snackBar.openFromComponent(
      SnackBarComponent,
      config,
    );
  }

  success(message: string, duration = 3500) {
    this.open({ type: 'success', message }, duration);
  }

  warning(message = SnackbarMessage.FORM_WARNING, duration = 3500) {
    this.open({ type: 'warning', message }, duration);
  }

  error(message = SnackbarMessage.ERROR_DEFAULT, duration = 3500) {
    this.open({ type: 'error', message }, duration);
  }

  info(message = SnackbarMessage.NOT_FOR_MVP, duration = 2500) {
    this.open({ type: 'info', message }, duration);
  }

  resetData(type: Snackbar['type']): Snackbar {
    return { title: '', message: '', type, _snackRef: null };
  }

  // TODO implement
  showInQueue() {
    this.snackBar.openFromComponent(SnackBarQueueComponent, {
      duration: 9999999,
      verticalPosition: 'top',
      horizontalPosition: 'right',
      data: [
        {
          notifyNumber: 1,
          routerLinkUrl: '/reports',
          routerLinkText: 'Go Download Reports',
          message: 'reports are ready to download in the "Reports" section.',
          icon: 'si-document-f',
        },
      ],
    });
  }
}
