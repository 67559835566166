import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-images-block',
  templateUrl: './images-block.component.html',
  styleUrls: ['./images-block.component.scss'],
})
export class ImagesBlockComponent implements OnInit, OnChanges {
  @Input() images: string[];

  currImage: string;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    this.setCurrent(this.images[0]);
  }

  setCurrent(src: string) {
    this.currImage = src;
  }
}
