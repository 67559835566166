import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private readonly _isBrowser: boolean;

  constructor(@Inject(PLATFORM_ID) private platformId: object) {
    this._isBrowser = isPlatformBrowser(this.platformId);
  }

  setItem(key: string, data: any, storage?) {
    if (this._isBrowser) {
      (storage || window.localStorage).setItem(
        key,
        typeof data === 'string' ? data : JSON.stringify(data),
      );
    }
  }

  getItem(key: string, storage?): string {
    return (
      (this._isBrowser && (storage || window.localStorage).getItem(key)) ||
      sessionStorage.getItem(key) ||
      null
    );
  }

  getItemParsed<T>(key: string, storage?): T {
    return (
      (this.getItem(key, storage) && JSON.parse(this.getItem(key))) || null
    );
  }

  removeItem(key: string, storage?) {
    if (this._isBrowser) (storage || window.localStorage).removeItem(key);
  }
}
