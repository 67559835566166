import { Component, Input } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { ClockMode } from 'mat-timepicker/lib/interfaces-and-types';

@Component({
  selector: 'app-universal-timepicker',
  templateUrl: './universal-timepicker.component.html',
  styleUrls: ['./universal-timepicker.component.scss'],
})
export class UniversalTimepickerComponent {
  @Input() fControl: FormControl | AbstractControl;

  @Input() mode: ClockMode = '12h';

  // style
  @Input() label = '';
  @Input() placeholder = '';
  @Input() clearBtn = false;
  @Input() required = false;
  @Input() cssClass = '';

  @Input() minDate: Date;
  @Input() maxDate: Date;
}
