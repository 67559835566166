import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PromptParams } from '@services/modal';

@Component({
  selector: 'app-csv-modal',
  templateUrl: './csv-modal.component.html',
  styleUrls: ['./csv-modal.component.scss'],
})
export class CSVModalComponent implements OnInit {
  public fields = {
    name: true,
    email: true,
    bio: true,
    status: true,
    dueDate: true,
    breastfeedingGoal: true,
    maternityLeaveEnd: true,
    companyName: true,
    companyHrName: true,
    corporation: true,
    type: true,
    role: true,
    createdAt: true,
    tribes: true,
    code: true
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PromptParams,
    private dialogRef: MatDialogRef<CSVModalComponent>,
  ) {}

  ngOnInit() {}

  download() {
    if (this.downloadPossible()){
      this.dialogRef.close(this.fields);
    }
  }

  downloadPossible() {
    return Object.values(this.fields).some(item => item);
  }
}
