import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ValidatorFn,
} from '@angular/forms';

interface ITestErrorAndSetOnControlParams {
  regExp: RegExp;
  fControl: AbstractControl;
  errorMessage?: string;
}

@Injectable({
  providedIn: 'root',
})
export class FormsService {
  constructor() {}

  // tslint:disable-next-line:ban-types
  createFormData(
    object: object,
    form?: FormData,
    namespace?: string,
    fileNamePrefix?: string,
  ): FormData {
    const formData = form || new FormData();

    for (const property in object) {
      if (
        !object.hasOwnProperty(property) ||
        object[property] === null ||
        typeof object[property] === 'undefined'
      ) {
        continue;
      }

      const formKey = namespace ? `${namespace}[${property}]` : property;

      if (object[property] instanceof Date) {
        formData.append(formKey, object[property].toISOString());
      } else if (
        typeof object[property] === 'object' &&
        !(object[property] instanceof File) &&
        !(object[property] instanceof Blob)
      ) {
        this.createFormData(
          object[property],
          formData,
          formKey,
          fileNamePrefix,
        );
      } else if (
        typeof object[property] === 'object' &&
        object[property] instanceof File &&
        object[property] instanceof Blob
      ) {
        formData.append(
          formKey,
          object[property],
          fileNamePrefix + object[property].name,
        );
      } else {
        formData.append(formKey, object[property]);
      }
    }

    return formData;
  }

  validateAllFormFields(
    formGroup: FormGroup | FormArray,
    markAs: string = 'markAsTouched',
  ) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);

      if (control instanceof FormControl) {
        return control[markAs]();
      }

      if (control instanceof FormArray) {
        if (control.controls.length) {
          if (control.controls[0] instanceof FormControl) {
            (control as FormArray).controls.forEach((ctrl: FormControl) => {
              return ctrl[markAs]();
            });
          }
          if (control.controls[0] instanceof FormGroup) {
            (control as FormArray).controls.forEach((ctrl: FormGroup) => {
              this.validateAllFormFields(ctrl, markAs);
            });
          }
        } else {
          (control as FormArray)[markAs]();
        }
      }

      if (control instanceof FormGroup) {
        this.validateAllFormFields(control, markAs);
      }
    });
    formGroup[markAs]();
  }

  hideValidateAllFormFields(formGroup: FormGroup | FormArray) {
    this.validateAllFormFields(formGroup, 'markAsUntouched');
  }

  isFileInvalid(ableTypes: Array<string>, maxFileSizeKb: number): ValidatorFn {
    return (currCtrl: AbstractControl) => {
      if (currCtrl.value && typeof currCtrl.value !== 'string') {
        if (currCtrl.value.size > maxFileSizeKb * 1024) {
          return { size: { error: 'Max file size of 2 Mb.' } };
        }
        if (ableTypes.indexOf(currCtrl.value.type) === -1) {
          return { type: { error: 'Must be a pdf' } };
        }
        return null;
      } else {
        return null;
      }
    };
  }

  getControlName(c: AbstractControl): string | null {
    const formGroup = c.parent.controls;
    return Object.keys(formGroup).find((name) => c === formGroup[name]) || null;
  }

  setErrorOnFormControl(fControl: AbstractControl, errorName: string) {
    fControl.setErrors({ [errorName]: true });
  }

  removeErrorOnFormControl(fControl: AbstractControl, errorName: string) {
    fControl.setErrors({ [errorName]: false });
  }

  setSpecificErr(fControl: AbstractControl, errorMessage: string) {
    fControl.setErrors({ specific: errorMessage });
  }

  /**
   *
   * @param errors<string[]>
   * @param paramsArr<{regExp: RegExp, fControl: AbstractControl, errorMessage: string}[]>
   */
  testErrorResponseAndSetSpecificErrorOnControl(
    errors: string[],
    paramsArr: ITestErrorAndSetOnControlParams[],
  ) {
    (errors || []).forEach((msg) => {
      paramsArr.forEach((param) => {
        // tslint:disable-next-line:no-unused-expression
        param.regExp.test(msg) &&
          this.setSpecificErr(param.fControl, param.errorMessage || msg);
      });
    });
  }

  removeSpecificErrorOnFormControl(fControl: AbstractControl) {
    fControl.setErrors({ specific: false });
  }
}

export function noWhitespaceValidator(control: FormControl) {
  const isWhitespace = (control.value || '').trim().length === 0;
  const isValid = !isWhitespace;
  return isValid ? null : { whitespace: true };
}
