import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

@Component({
  selector: 'app-universal-toggle',
  templateUrl: './universal-toggle.component.html',
  styleUrls: ['./universal-toggle.component.scss'],
})
export class UniversalToggleComponent implements OnInit {
  @Input() fControl: FormControl | AbstractControl;

  // style
  @Input() label: string;
  @Input() wrapperCssClass: string;

  @Output() chaneEvent = new EventEmitter();

  constructor() {}

  onChange(e) {
    this.chaneEvent.emit(e);
  }

  ngOnInit() {}
}
