import { Component, OnInit } from '@angular/core';
import { IconsService } from '@services/icons';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isLoading = true;

  progressbarOptions = {
    min: 8,
    max: 100,
    ease: 'linear',
    speed: 200,
    trickleSpeed: 300,
    meteor: true,
    spinner: false,
    spinnerPosition: 'right',
    direction: 'ltr+',
    color: '#02C5B4',
    thick: true,
  };

  constructor(private iconsSrv: IconsService) {}

  ngOnInit() {
    this.iconsSrv.registerIcons();
  }

  onActivate() {
    this.isLoading = false;
  }
}
