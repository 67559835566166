<div class="input-container input-type-autocomplete {{ wrapperCssClass }}">
  <mat-form-field>
    <mat-label>{{ label }}</mat-label>

    <!-- AUTOCOMPLETE '(focus)="autoInput.opened.emit($event)"' - is workaround for issue with first open-->
    <input
      matInput
      type="text"
      [placeholder]="placeholder"
      [formControl]="fControl"
      [matAutocomplete]="autoInput"
      (focus)="autoInput.opened.emit()"
      (click)="onClickInput()"
    />

    <!-- ERROR MESSAGE // don't use *ngIf because of ExpressionChangedAfterItHasBeenCheckedError-->
    <mat-error [style.display]="fControl.invalid ? 'inline-block' : 'none'">
      {{ fControl.errors | getErrorMessage | stringNormalizer }}
    </mat-error>

    <!-- CLEAR BUTTON -->
    <button
      type="button"
      aria-label="Clear"
      mat-button
      matSuffix
      mat-icon-button
      (click)="onClear()"
      *ngIf="clearBtn && fControl.value"
    >
      <mat-icon>close</mat-icon>
    </button>

    <!-- AUTOCOMPLETE options -->
    <mat-autocomplete
        [useInfinityScroll]="useInfinityScroll"
        (scrolled)="onScrolled()"
        #autoInput="matAutocomplete"
        [displayWith]="displayOption"
    >
      <ng-container *ngIf="fControl.value?.length >= queryMinLength">
        <mat-option *ngIf="acts.isSearching" disabled>
          <app-waiting [size]="40"></app-waiting>
        </mat-option>
        <mat-option *ngIf="fControl.value && !acts.isSearching && !optionsArr.length" disabled>Not found</mat-option>
      </ng-container>
      <mat-option *ngFor="let option of optionsArr" [value]="option">{{ option.viewValue }}</mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
