import { Injectable } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PaginatedData, QueryParams } from '@interfaces';

interface RegisterParams {
  sort: MatSort;
  paginator: MatPaginator;
  acts?: { isLoading: boolean };
  request(params: QueryParams): Observable<any>;
}

/**
 * Note: Always import directly to providers!
 */
@Injectable()
export class QueryService {
  private _destroyer$ = new Subject();
  private _onSearch$ = new Subject<PaginatedData<any>>();
  private _prevQuery = new QueryParams();

  private acts: RegisterParams['acts'];
  private request: RegisterParams['request'];
  private sort: MatSort;
  private paginator: MatPaginator;

  constructor() {}

  register({
    sort,
    paginator,
    request,
    acts,
  }: RegisterParams): Subject<PaginatedData<any>> {
    this.sort = sort;
    this.paginator = paginator;
    this.request = request;
    this.acts = acts || { isLoading: false };
    sort.sortChange
      .pipe(takeUntil(this._destroyer$))
      .subscribe(() => this.initSearch());
    paginator.page
      .pipe(takeUntil(this._destroyer$))
      .subscribe(() => this.initSearch());
    return this._onSearch$;
  }

  initSearch(param?: QueryParams) {
    this.acts.isLoading = true;

    if (param?.query !== undefined) {
      this._prevQuery.query = param.query;
      this._prevQuery.searchKeys = param.searchKeys;
      this._prevQuery.filters = param.filters;
    }
    this._prevQuery.page = this.paginator.pageIndex + 1;
    this._prevQuery.sort = this.sort.direction ? this.sort.active : undefined;
    this._prevQuery.order = this.sort.direction || undefined;
    this._prevQuery.limit = this.paginator.pageSize;

    this.request(this._prevQuery).subscribe(
      (res: PaginatedData<any>) => {
        this.paginator.length = res.totalItems;
        this.paginator.pageIndex = res.currPage - 1;
        this.acts.isLoading = false;
        this._onSearch$.next(res);
      },
      () => (this.acts.isLoading = false),
    );
  }

  destroy() {
    this._destroyer$.complete();
  }
}
