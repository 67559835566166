import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Location } from '@angular/common';

type CtrlBtn = 'edit' | 'delete' | 'save' | 'activate';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent implements OnInit {
  @Input() title = '';
  @Input() userBanned: boolean;
  @Input() ctrlButtons: CtrlBtn[] = [];

  @Output() emitEdit = new EventEmitter();
  @Output() emitDelete = new EventEmitter();
  @Output() emitSave = new EventEmitter();
  @Output() emitActivate = new EventEmitter();

  constructor(private location: Location) {}

  ngOnInit(): void {}

  goBack() {
    this.location.back();
  }
}
